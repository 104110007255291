@import "~sass/utilities";

.container {
    display: grid;
    grid-template-columns: auto 480px;

    @media only screen and (max-width: 480px) {
        grid-template-columns: 1fr;
    }
    grid-gap: 20px;
    width: 100%;
    box-sizing: border-box;
    max-width: 960px;
    margin: 20px auto;
    form > h2 {
        text-align: center;
    }

    .intro {
        text-align: left;
        img {
            max-width: 120px;
        }
    }
}
.links {
    margin-top: 20px;
    text-align: left;
    a {
        margin-right: 20px;
    }
}
