.entityTile {
  height: 80px;
  width: 80px;
  display: inline-block;
  cursor: pointer;

  &.disabled {
    background-color: #D9D9D9;
    opacity: 0.5;
  }
}

.disabledIcon {
  color: red;
  margin: 5px 0 0 calc(80px - 15px - 5px);
  position: absolute;
}

.jobSelectBackground {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.jobSelectForm {
  width: 300px;
  background-color: white;
  padding: 0 16px;
}

.jobSelectButton {
  margin: 16px 0;
}
