.content {
    margin: 25px;
}

.buttonContainer {
  grid-column-start: 2;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
