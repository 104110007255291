.container {
  padding: 40px;
}

.imageSlider {
  :global(.slick-slide) > div {
    display: flex;
    justify-content: center;
    padding: 40px 30px 0;

    img {
      margin: 20px;
    }
  }
}

.canvasSlider {
  :global(.slick-slide) > div {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    // is not needed for the div itself - only to be able to use absolute positioning within it
    position: relative;

    img {
      /*
      I am trying to cover `20px 20px` margin from a canvas (as well as all its
      width) as much as possible.
      And this seems to be the most browser-agnostic value.
      */
      margin: 20px auto 20px 20px;
      max-width: 100%;
      max-height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      height: inherit;
      z-index: 0;
      opacity: 0;
      min-width: 100%;
    }

    canvas {
      max-width: 100%;
      max-height: 80vh;
      margin: 20px 20px;
    }
  }
}

/* !important everywhere because I need to force the overriding of default styles */
.sliderArrow {
  width: 60px !important;
  height: 60px !important;
  /* Button may be covered with an image itself without higher z-index */
  z-index: 1;

  &:before {
    font-size: 60px !important;
  }
}
.sliderArrowPrev {
  left: -40px !important;
}
.sliderArrowNext {
  right: -40px !important;
}
