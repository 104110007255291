.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,0.8);
  z-index: 5;

  .removeModal {
    background: transparent;
    position: absolute;
    border: none;
    color: white;
    font-size: 30px;
    top: 5%;
    right: 5%;
    cursor: pointer;
  }
}
