@import "~sass/utilities";

.container {
    background: $white;
    border-left: 2px solid $info-color;
    animation: border-pulsate 2s infinite;

    padding: 20px;
    p {
        margin: 0;
    }
    li {
      list-style-type: none;
    }
}

@keyframes border-pulsate {
    0%   { border-color: $info-color; }
    50%  { border-color: $danger-color; }
    100% { border-color: $info-color; }
}
