.container {

}

.select {
  width: 100%;
  display: inline-block;
  [class$="-control"] {
    height: 42px;

    border-radius: 0;
    border-style: solid;
    border-width: 1px;

    &>div:nth-of-type(1) { // that div has a very cryptic class name
      height: 4rem;
    }
  }

  [class$="-menu"] {
    border-radius: 0;
  }
}
