.formGrid {
  display: grid;
  margin: 4rem;
  grid-template-columns: 6rem 40rem;
  grid-template-rows: 4rem 3rem;
  gap: 2rem 10rem;
}

.nameLabel {
  align-self: center;
}

.nameInput {
  background-color: white;
}
