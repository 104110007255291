@import "~sass/utilities";

.content {
    margin: 25px;
}

.content h1 {
    margin: 50px 0px 25px;
}

.table {
  table-layout: fixed;
}
