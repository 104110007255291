@import "~sass/utilities";

.pagination {
    display: flex;
    list-style: none;
    background: #fff;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 10px;

    li {
        a {
            cursor: pointer;
            padding: 8px 10px;
            &.active {
            background: $primary-color;
            color: #fff;
            }
        }
    }
}
