@import "~sass/utilities";

.labeledSwitch {
  display: inline-flex;
  justify-content: space-between;
  width: 12rem;
  font-size: calc(100% - 2rem);
  background-color: $white;
  border: solid 1px $primary-color;
  cursor: pointer;
}

.labeledSwitch.checked {
  background-color: $primary-color;
  border-color: $white;
  flex-direction: row-reverse;
}

.labeledSwitch.disabled {
  cursor: not-allowed;
}

.lever {
  float: left;
  border: solid 2px $gray-500;
  margin: .2rem;
  background-color: $white;
}

.lever.checked {
  float: right;
}

.line {
  height: calc(100% - .6rem);
  border: solid 1px $gray-500;
  margin: .2rem .4rem;
  display: inline-block;
}

.label {
  color: $primary-color;
  font-size: 2rem;
  padding-left: .2rem;
  padding-right: .2rem;
  user-select: none;
  margin-top: auto;
  margin-bottom: auto;
}

.label.checked {
  color: $white;
}