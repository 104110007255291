@import "assets/sass/utilities";

.bar {
  background-color: $gray-700;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  align-items: center;
}

.chipBox {
  width: 100% !important;
}

.searchInput {
  padding: 0;
  width: 100%;
  height: 100%;
  border-width: 0;
  outline: none;
}

.searchButton {
  margin: 0.5rem;
  height: 42px;
}
