.container {
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  object-fit: contain;
  background: white;
  padding: 0 20px;
  overflow-y: scroll;
}