@import "~sass/utilities";

.box {
    margin-bottom: 20px;
        border: solid 1px $gray-400;

    .header {
        padding: 10px;
        h1,h2,h3,h4 {
            font-size: 16px;
            margin: 0;
            padding: 0;
        }
    }
    .content {
        background: white;
        border: 1px solid transparent;
        border-radius: 3px;
        padding: 10px;
        ul {
            padding-left: 10px;
            padding-bottom: 10px;
        }
        .grid {
            display: flex;
            flex-direction: column;
            button {
                margin-left: auto;
            }
            & > ul {
                margin: 0;
            }
        }
    }

    &.yellow {
        .header {
            background: $info-color;
        }
    }
    &.green {
        .header {
            background: $selected-color;
        }
    }
}
