@import "~sass/utilities";

.container {
    max-width: 480px;
    margin: 20px auto;
    h2 {
        text-align: center;
    }
}
.links {
    margin-top: 20px;
    text-align: left;
    a {
        margin-right: 20px;
    }
}
