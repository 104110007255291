.container {
  // Subtract the height of the search bar
  height: calc(100% - 52px);
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 30% auto;
}

.menu {
  background: white;
  overflow-y: auto;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      padding: 10px 25px;
      border-top: 1px solid #eee;
    }
  }
}

.content {
  padding: 25px;
  overflow-y: auto;
}
