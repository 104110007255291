.container {
  label {
    margin-top: 0;
  }

  max-width: 820px;
  margin-bottom: 25px;

  input,
  button {
    background: white;
  }
  input:not([type="checkbox"]) {
    height: 38px;
  }
}

.verticalContainer {
  display: grid;
  grid-gap: 15px;
  grid-template-rows: auto;
  grid-template-columns: max-content auto;
  align-items: center;
}

.horizontalContainer {
  display: flex;
  gap: 15px;
  align-items: center;

  > div {
    min-width: 200px;
  }

  @media screen and (max-width: 960px) {
    display: grid;
    grid-gap: 15px;
    grid-template-rows: auto;
    grid-template-columns: max-content auto;
  }
}
