.labelStatus {
  position: absolute;
  right: 5px;
  bottom: 5px;
  display: inline-block;
  width: 15px;
  height: 15px;
  text-align: center;
  cursor: help;

  &:before {
    content: attr(data-tooltip);
    display: none;
    position: absolute;
    background: #fff;
    color: #000;
    padding: 5px;
    font-size: 12px;
    line-height: 1.4;
    min-width: 100px;
    text-align: center;
    border-radius: 4px;
    right: 100%;
    bottom: 100%;
  }
  &:hover:before {
      display: block;
      z-index: 50;
  }
}
