.table {
    width: 100%;
    background: white;
    border-collapse: collapse;
    border-spacing: 0;
    th {
        text-align:left;
    }
    tr {
        /* Cells in even rows (2,4,6...) are one color */
        &:nth-child(even) td { background: #F1F1F1; }
        /* Cells in odd rows (1,3,5...) are another (excludes header cells) */
        &:nth-child(odd) td { background: #FEFEFE; }
    }
    th, td {
        padding: 15px;
        overflow: auto;
    }
    th {
        background: #DFDFDF; /* Darken header a bit */
        font-weight: bold;
    }
}
