$white: #fffcf9 !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #000 !default;

$primary-color: #5bc0eb;
$danger-color: #ef476f;
$selected-color: #06d6a0;
$info-color: #ffd166;

$h1-font-size: 32px;

.container {
    display: grid;
    grid-template-rows: auto;

    grid-template-areas:
        "sidebar main"
        "sidebar main";

    @media only screen and (max-width: 480px) {
        grid-template-areas:
            "sidebar sidebar"
            "main main";
        grid-template-columns: auto;
    }
    @media screen and (min-width: 480px) {
        height: 100%;
        grid-template-columns: max-content auto;
    }
    @media screen and (min-width: 960px) {
        grid-template-columns: max-content auto;
    }
    @media screen and (min-width: 1200px) {
        grid-template-columns: max-content auto;
    }
}
.content {
    grid-area: main;
    box-sizing: border-box;

    overflow-x: hidden;
    overflow-y: auto;
}

html {
    font-size: 62.5%;
}

body,
input,
button {
    font-size: 1.4rem;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: #ececec;
}

a {
    text-decoration: none;
    color: $primary-color;
}

:global(a.active) {
    color: $primary-color !important;
}

table {
    width: 100%;
    th {
        text-align: left;
    }
}

img,
iframe {
    max-width: 100%;
}

.disable-selection {
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
