.infoButton {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
}

.hideButton {
  cursor: pointer;
  display: flex;
  flex-direction: row-reverse;
}

.appendableCard {
  height: fit-content;
}
