@import "assets/sass/utilities";

.selectByWrapper {
  margin: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.wrapper {
  margin: 2rem;
}

.creatorFilterSelector {
  width: 32rem;
}

.bar {
  grid-area: filter;
  background-color: $gray-700;
  display: flex;
  justify-content: space-between;
}
