.container {
  padding: 25px;

  table {
    tr {
      height: 50px;
      p {
        margin: 0;
      }
      td {
        padding: 5px 15px;
        div {
          max-height: 40px;
        }
      }
    }
  }
}