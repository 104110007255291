@import "~sass/utilities";

.container {
    height: 100%;
    display: grid;

    grid-template-areas:
        "header"
        "content";
    grid-template-rows: min-content auto;
}

.header {
    grid-area: header;
}

.contentWrapper {
    overflow: hidden;
}
.content {
    grid-area: content;
    height: 100%;
    overflow: hidden;
    display: grid;
    justify-items: stretch;
    align-items: stretch;
    grid-template-rows: auto;

    @media only screen and (max-width: 480px) {
        grid-template-columns: 1fr;
    }
    @media screen and (min-width: 480px) {
        grid-template-columns: 30% 1fr;
    }
    @media screen and (min-width: 960px) {
        grid-template-columns: 40% 1fr;
    }
    @media screen and (min-width: 1200px) {
        grid-template-columns: 25% 1fr;
    }
}
.actions {
    display: flex;
    list-style: none;
    background: #fff;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;

    li {
        a {
            cursor: pointer;
            padding: 8px 10px;
            &.active,
            &:hover {
                background: $primary-color;
                color: #fff;
            }
        }
    }
}

.activeEntity {
    background: red;
}
