.labeledSwitch {
  margin-left: 2rem;
  margin-top: .5rem;
  height: 3.8rem;
}

.labeledSwitchWrapper {
  display: inline-block;
  position: absolute;
}

.disabledButton {
  opacity: 50%;
}

.error {
  position: fixed;
  bottom: 0;
  height: 16rem;
  overflow-y: scroll;
  margin: 0;
}
