.container {
    height: 100%;
    display: grid;
    grid-template-areas:
        "header"
        "submenu"
        "content";
    grid-template-rows: 70px 47px auto;
}

.content {
    grid-area: content;
    overflow-y: auto;
}
