.header {
  grid-area: header;
  display: grid;
  grid-template-columns: max-content auto max-content;
  align-items: center;
  grid-gap: 10px;
  padding: 0 25px;
  background: #fff;
  min-height: 70px;
  overflow-x: auto;
  list-style: none;
  a {
    float: right;
  }
}
