.formComponent {
  margin: 20px;
}

.selectAllRow {
  margin-left: 14px;
}

.selectJobRow {
  margin-left: 25px;
}

.teamExpander {
  cursor: pointer;
  display: inline-block;
  width: 14px;
}

.teamCheckbox {
  margin-right: 10px;
  margin-left: 15px;
  cursor: pointer;
}

.jobCheckbox {
  margin-right: 10px;
  cursor: pointer;
}

.deletedJob {
  color: red;
}
