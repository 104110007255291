@import "~sass/utilities";

.zone {
    color: $gray-500;
    border: 3px dashed $gray-400;
    border-radius: 7px;
    padding: 40px;
    text-align: center;
    margin-bottom: 20px;

    &:hover {
        cursor: pointer;
        color: $gray-700;
        border-color: $gray-600;
    }
}
