@import "~sass/utilities";
.container {
    margin-top: 20px;
    button {
        border-color: transparent;
        height: 30px;
        width: 100%;
        display: block;
        background: $gray-700;
        color: $gray-300;
        margin-top: 20px;
    }
}
