.buttonsContainer {
  margin-top: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.card {
  canvas {
    margin: auto;
    width: 100%;
  }
  h2 {
    display: none;
  }
  div {
    text-align: center;
  }
}
