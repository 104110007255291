.container {
  height: 100%;
  display: grid;
  grid-template-areas:
    "header"
    "submenu"
    "content";
  grid-template-rows: 70px 47px auto;
}

.content {
  grid-area: content;
  overflow-y: hidden;
}

.start {
  background: #5bc0eb;
  border-radius: 5px;
  &:hover {
    background: #6ed4ff;
  }

  a {
    color: white !important;
  }
}
