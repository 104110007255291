@import "assets/sass/utilities";

.field {
  background-color: $white;
  width: 90%;
  margin: 0.5rem;
  padding: 0.4rem;
  display: flex;
  justify-content: space-between;
  height: 34px;
}

.iconBox {
  padding: 0.3rem 0.4rem;
  border-radius: 50%;
}

svg.icon {
  color: $gray-700;
  width: 2.5rem;
  height: 2.5rem;
}

.chipBox {
  width: calc(100% - 10rem);
}

.clearButton {
  cursor: pointer;
  &:hover, &:hover, &:active {
    background-color: $gray-200;
  }
}
