@import "~sass/utilities";

.container {
  background: white;
  padding: 12px 10px;
  overflow-y: auto;
  outline-width: 0;

  .metabox {
    border: 1px $gray-400 solid;
    color: $gray-600;
    padding: 10px;
    margin-bottom: 20px;
    ul,
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }

  p {
    color: $gray-500;
    padding: 14px;
  }

  .field {
    width: 100%;
    box-sizing: border-box;
    position: relative;
    margin: 0px 0px 5px;
    border: 1px solid $gray-400;

    &.active {
      border: 1px solid red;
    }
    &.disabled, &.disabled input {
      background-color: rgb(211,211,211);
    }

    .autocomplete {
      max-height: 300px;
      overflow-y: scroll;
      overflow-x: hidden;
      background: $gray-200;
      padding-bottom: 5px;
      div {
        display: inline-block;
        border: 1px solid $gray-200;
        padding: 5px 10px;
        box-sizing: border-box;
        background: #fff;

        &.activeLabel {
          border-bottom: 2px red solid;
        }
        &.recommendation {
          border-bottom: 2px $primary-color solid;
        }

        &:hover {
          background: $primary-color;
          cursor: pointer;
        }
      }
    }
  }

  .delete {
    color: black;
    background: white;
    border: none;
    border-radius: 100%;
    padding: 0;
    position: absolute;
    right: -8px;
    top: -8px;
    cursor: pointer;
    line-height: 0;

    &:hover {
      color: $gray-500;
    }
  }
}

.fieldInput {
  height: 36px;
  width: 80%;
  box-sizing: border-box;
  border: 0 transparent solid;
  padding-left: 10px;
  margin-bottom: 5px;
  outline: none;
}

.entityData {
  padding: 5px;
  border-top: 1px solid #e9ecef;
  display: flex;
  /* At least for now. No point to try and guess, what will be better when there
   will be more elements */
  justify-content: space-between;
  @media screen and (min-width: 480px) and (max-width: 799px) {
    flex-direction: column;
    justify-content: start;
  }
}

svg.deleteIcon {
  width: 2rem;
  height: 2rem;
}

.enabledCheckbox {
  margin-left: -0.7rem;
}

.confidenceWrapper {
  display: flex;
  justify-content: start;

  @media screen and (min-width: 480px) and (max-width: 959px) {
    flex-direction: column;
  }
}

.confidenceLabel {
  margin-right: 5px;
}

.confidence {
  border: 1px solid #e9ecef;
  width: 100px;
  height: 16px;
  position: relative;
}

.confidenceBar {
  background-color: #e9ecef;
  height: 100%;
}

.confidenceValue {
  position: absolute;
  top: 0;
  right: 25%;
}
