@import "~sass/utilities";

.container {
    padding: 2rem;
    background: white;
    margin: 0 0 2rem;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-left: 5px solid $danger-color;
    p {
        margin: 0;
        font-weight: 100;
    }
    strong {
        margin-right: 1rem;
    }
}

.dismiss {
    position: absolute;
    right: 1rem;
    top: 1rem;
    border: none;
    background: none;
    cursor: pointer;
    font-size: large;
}
