@import "assets/sass/utilities";

// labelLink is my name for the <Link> containing the label name
// highlightRequests determines whether requested labels should be highlighted
// requested / missing indicate how a label should be highlighted
//     missing is a subset of requested
.labelLink.highlightRequests {
  color: $gray-500;
  &.requested {
    color: $primary-color;
    &.missing {
      color: $danger-color;
    }
  }
}
