.table, .table th {
  text-align: center;
}

.moonboxAppName {
  text-align: center;
}

.createInstanceLink {
  position: relative;
  right: 4rem;
  top: -3.5rem;
  text-align: right;
  height: 0;
}
