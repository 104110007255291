.table {
  table-layout: initial;
  text-align: center;
}

.table th {
  text-align: center;
}

.disabledButton {
  opacity: 50%;
}
