@import "~sass/utilities";

.cardWrapper {
  /* For absolute positioning inside to word */
  position: relative;
}

.container {
  border: 0 solid transparent;
  border-radius: 5px;
  background: #fff;
  //border-bottom: 5px solid transparent;
  position: relative;
  color: black;
  cursor: default;
  display: inline-block;
  width: 100%;
  height: 100%;

  &.clickable {
    cursor: pointer;
    &:hover {
      background: $gray-400;
    }
  }

  .hoverButtons {
    position: absolute;
    top: 5px;
    right: 5px;
    /* z-index should be bigger than overlay image for canvas cards */
    z-index: 1;
    display: none;
  }
  &:hover .hoverButtons {
    display: block;
  }

  img {
    width: 100%;
    max-height: 200px;
    object-fit: cover;
  }
  .body {
    padding: 0px 20px;

    h2 {
      max-width: 300px;
      overflow-wrap: break-word;
    }

    ul {
      display: flex;
      padding: 0;
      list-style: none;

      li > a {
        border: 1px solid $gray-400;
        border-radius: 3px;
        padding: 5px 8px;
        margin: 0 5px 0 0;
        color: #000;

        &:hover {
          background: $gray-400;
        }
      }
    }
  }
  &.selected {
    border-bottom: 5px solid $selected-color;
  }
}

.multiModeCheckbox {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 25px;
  height: 25px;
  display: inline-block;
  z-index: 1;
}
