.container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 25px;
    justify-items: stretch;

    @media screen and (min-width: 480px) {
        grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: 960px) {
        grid-template-columns: 1fr 1fr 1fr;
    }
    @media screen and (min-width: 1400px) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
    @media screen and (min-width: 1600px) {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
}
