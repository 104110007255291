.image {
  position: absolute;
  top: 10%;
  left: 10%;
  right: 10%;
  bottom: 10%;
  object-fit: contain;
  display: flex;

  img {
    margin: auto;
    max-width: 100%;
    max-height: 100%;
  }
}
