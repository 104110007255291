.video {
    width: 240px;
    height: 180px;
    display: inline-block;
    position: relative;
    margin-right: 20px;

    video {
        width: 100%;
        height: 100%;
        background: #000;
    }
}

.remove {
    width: 10px;
    height: 10px;
    top: 10px;
    right: 10px;
    position: absolute;
    cursor: pointer;
    color: #fff;
    font-weight: bold;
}

.grid {
    display: grid;
    grid-template-columns: max-content 400px min-content;

    label {
        padding: 0 20px 0 0;
        align-self: center;
    }
    button {
        margin: 0 0 0 20px;
        padding: 10px;
    }
}
.input {
    margin-bottom: 20px;
    .grid {
        display: grid;
        grid-template-columns: max-content auto;
        align-items: center;
        label {
            padding: 10px 20px 10px 0px;
        }
    }
}

.canvasContainer {
    border: 1px solid #343a40;
    height: 400px;
}

.hiddenCanvas {
    display: none;
}

.inputRange {
    margin-bottom: 25px;
}
