.container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.containerRight {
  flex-basis: 20%;
}

.type {
  display: block;
  line-height: 2rem;
}

.value {
  display: block;
  line-height: 2rem;
}
