@import "~sass/utilities";

.button {
  background: $gray-200;
  color: $gray-800;
  padding: 5px 10px;
  text-align: center;
  transition: background 0.3s ease-in-out;
  display: block;

    &:hover {
      cursor: pointer;
      background: $gray-400;
    }

    &.big {
      width: 12rem;
      font-size: 2rem;
      height: 4rem;
    }
}
