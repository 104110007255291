.imageWrapper {
  background-color: #222;
  width: 100%;
  height: 120px;
}

.canvasImage {
  height: 100%;
  width: 100%;
  object-position: center;
  object-fit: contain;
  /* Need to override styles that are applied later */
  margin: auto !important;
}
