/* Variables*/
@import "~sass/utilities";

html {
    font-size: 62.5%;
}

body,
input,
button {
    font-size: 1.4rem;
}

html,
body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    background: #ececec;
}

a {
    text-decoration: none;
    color: $primary-color;
}

:global(a.active) {
    color: $primary-color !important;
}

table {
    width: 100%;
    th {
        text-align: left;
    }
}

img,
iframe {
    max-width: 100%;
}

.disable-selection {
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer */
    -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
    -webkit-user-select: none; /* Chrome, Safari, and Opera */
    -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
}
