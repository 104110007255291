.card {
  cursor: default;
  canvas {
    margin: auto;
    width: 100%;
  }
  h2 {
    display: none;
  }
  p {
    margin: 0;
  }
  div {
    text-align: center;
    padding: 0 !important;
  }
}

.downloadPreviewContainer {
  display: flex;
  flex-direction: column;
}

.buttonAlign {
  margin: 10px;
  align-self: center;
}
