@import "~sass/utilities";

.container {
    padding: 10px;
    background: white;
    margin-top: 20px;

    h3 {
        color: $gray-500;
        margin: 0 0 10px;
        font-weight: normal;
    }
    ul {
        padding-left: 1em;
        max-height: 200px;
        overflow-y: scroll;
    }
}

.loading:after {
  content: '.';
  animation: dots 4s steps(5, end) infinite;
}

@keyframes dots {
  0%, 20% {
    color: rgba(0,0,0,0);
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  40% {
    color: black;
    text-shadow:
      .25em 0 0 rgba(0,0,0,0),
      .5em 0 0 rgba(0,0,0,0);
  }
  60% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 rgba(0,0,0,0);
  }
  80%, 100% {
    text-shadow:
      .25em 0 0 black,
      .5em 0 0 black;
  }
}
