@import "~sass/utilities";

.container {
    background: $gray-700;
    border: 1px solid $gray-800;

    ul {
        display: flex;
        margin: 6px 0px;
    }
    li {
        display: flex;
        align-items: center;

        &.seperator {
            width: 2px;
            background: $gray-800;
            margin: 0px 5px;
        }
    }
    a {
        color: #dddddd;
        padding: 4px;
        cursor: pointer;
        border: transparent 0px solid;
        border-radius: 2px;

        &:hover {
            background: $gray-800;
        }
        &.active {
            background: $gray-900;
        }
        svg {
            height: 25px;
        }
    }
    .disabled > a {
        opacity: 0.6;
        cursor: not-allowed;

        &:hover {
            background: transparent;
        }
    }
}
