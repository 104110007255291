.actionBar {
  height: 5rem;
  background-color: #495057;
}

.label {
  font-weight: bolder;
  color: white;
  margin-left: 3rem;
  margin-right: 2rem;
}

// See https://stackoverflow.com/a/63970804
.select {
  width: 24rem;
  display: inline-block;
  // vertical center select in actionbar
  margin-top: .5rem;

  &:focus {
    border-color: #2684ff;
    border-width: 2px;
  }

  input {
    color: white !important;
  }

  [class$="-placeholder"], [class$="-singleValue"] {
    color: white;
  }

  [class$="-control"] {
    background-color: unset;
    border-color: #ccc;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
  }

  [class$="-menu"] {
    border-radius: 0;
  }
}

.disabled {
  opacity: 50%;
}

.buttonGroup {
  float: right;
  margin-right: 2.5rem;
}

.buttonGroup button {
  margin-top: 1rem;
  margin-right: .5rem;
}
