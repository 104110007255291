@import "~sass/utilities";

.container {
   width: 100%;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-content: center;

   .component {
       box-sizing: border-box;
        padding: 40px;
        width: 100%;

        svg {
            width: 27px;
            height: 27px;
            margin: auto;
            display: block;
        }
        p {
            margin: auto;
            text-align: center;
            max-width: 120px;
            color: $gray-500;
        }
   }
}
