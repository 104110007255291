@import "~sass/utilities";

.container {
    grid-area: submenu;

    padding: 10px 15px;
    background: white;

    border-top: 1px solid $gray-300;

    ul, li {
        margin: 0;
        padding: 0;
        list-style: none;
    }
    ul {
        display: flex;
    }
    li {
        display: flex;
        align-items: center;
        h2 {
            padding-left: 10px;
        }
        a {
            color: $gray-700;
            padding: 5px;
            margin: 0 5px;
            display: block;

            &:hover, &.active {
                color: $primary-color;
            }
        }
        button {
            color: $danger-color;
            background: transparent;
            border: none;
        }
    }
}
