@import "~sass/utilities";

.sidebar {
  grid-area: sidebar;
  background: $gray-800;
  min-width: 180px;
  padding: 20px;
  box-sizing: border-box;

  .nav {
    svg {
      &.logo {
        height: 60px;
        margin: 20px auto;
        display: block;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      padding-top: 20px;

      li {
        padding: 10px 0px;

        a {
          color: $gray-300;
          text-transform: uppercase;
          text-decoration: none;

          &:hover {
            color: $primary-color;
          }
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    height: 60px;
    padding: 10px;
    overflow: hidden;

    &.open {
      height: 100%;
      padding: 10px;
    }
    .nav svg {
      height: 40px;
      margin: 0px auto;
    }
  }

  .pointer {
    cursor: pointer;
  }
}
