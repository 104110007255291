.container {
    display: grid;
    grid-template-rows: minmax(0, 1fr);
    grid-template-areas:
        "side content"
        "side content"
        "side content";
    grid-template-columns: 10% auto;

    .side {
        position: relative;
        padding-top: 25px;
        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 0;
            bottom: 0;
            width: 1px;
            background: #ccc;
        }
    }

    .number {
        background: #fff;
        border: solid 1px #ccc;
        border-radius: 100%;
        line-height: 50px;
        text-align: center;
        width: 50px;
        height: 50px;
        margin: auto;
        position: relative;
    }

    .body {
        padding: 20px 10px;
    }
}

.inactive {
    opacity: 0.5;
}

.overflow {
    overflow-y: auto;
}
